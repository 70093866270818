<template>
    <div class="content">
        <div class="header">
            <img src="../assets/images/img_04.png">
        </div>
        <div class="main">
            <div v-for="(item, i) in lists" :key="i" class="item">
                <img :src="item.img">
                <div class="info" @click="handleClick(item.path)">
                    <p class="title">{{ item.title }}</p>
                    <p class="date">{{ item.date }}</p>
                </div>
            </div>
        </div>
        <div class="footer">
            <p>版权所有：山西幸福宜居数字生活服务集团有限公司     备案号：晋ICP备2022003090号</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "News",
        data() {
            return {
                lists: [
                    {
                        img: require('../assets/images/img_05.png'),
                        title: '又一家新店开业 I 嘉汇万邻超市凤翔社区店',
                        date: '2022.03.07 18:31',
                        path: '/news_01'
                    },
                    {
                        img: require('../assets/images/img_06.png'),
                        title: '【字里藏深情 欢乐闹元宵】南洋锦绣物业开展元宵节猜灯谜活动',
                        date: '2022.02.06 18:08',
                        path: 'news_02'
                    }
                ]
            }
        },
        methods: {
            handleClick(path) {
                this.$router.push(path)
            }
        }
    }
</script>

<style lang="scss" scoped>
.content{
    width: 100%;
    .header{
        width: 100%;
        img{
            width: 100%;
        }
    }
    .main{
        width: 100%;
        .item{
            display: flex;
            padding: 30px  60px;
            img{
                width: 348px;
                height: 248px;
            }
            .info{
                padding-left: 20px;
                cursor: pointer;
            }
            .title{
                padding-bottom: 15px;
                font-size: 20px;
                color: #131313;
            }
            .date{
                font-size: 16px;
                color: #CFCFCF;
            }
        }
    }
    .footer{
        padding: 20px 60px;
        p{
            font-size: 16px;
        }
    }
}
</style>